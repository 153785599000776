<!--
 * @Author: wyq
 * @Date: 2021-06-08 14:35:38
 * @LastEditTime: 2021-09-30 15:22:39
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\views\index.vue
-->
<template>
  <el-container class="page-index">
    <el-aside>
      <public-aside></public-aside>
    </el-aside>
    <router-view></router-view>
  </el-container>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.page-index {
  &::before {
    position: absolute;
    left: -8vw;
    bottom: -16vw;
    content: '';
    width: 42vw;
    height: 42vw;
    border-radius: 50%;
    border: 26px solid #0148ff;
    opacity: 0.1;
  }
}
</style>